import { type Ref, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import {
  type VideoImperativeControls,
  VideoPlayer,
  VideoVolumeButton,
} from "~components/ui";
import { VideoPlayButton } from "~components/ui/video-player/video-play-button";
import { type PageContext, getPosterUrl } from "~lib/storyblok";
import { cn, useScreenSize } from "~utils";
import type { FeatureSectionLink } from "./types";

interface FeatureSectionVideoProps extends PageContext {
  item: FeatureSectionLink;
}

function FeatureSectionVideo({ item, ...props }: FeatureSectionVideoProps) {
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.6,
  });

  const videoRef = useRef<VideoImperativeControls>();
  const isAutoplay = item?.autoplay ? inView : false;
  const isButtonHover = (isHover: boolean) => (isHover ? "block" : "hidden");
  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (!videoRef?.current) return;

    if (isAutoplay) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
      videoRef.current.mute();
    }
  }, [isAutoplay]);

  return item?.video?.filename ? (
    <VideoPlayer
      ref={inViewRef}
      videoRef={videoRef as Ref<VideoImperativeControls>}
      className={cn("z-10", item?.square ? "aspect-square" : "")}
      classNameVideo="object-cover"
      src={item.video.filename}
      disablePictureInPicture
      poster={
        item.poster
          ? getPosterUrl({
              image: item.poster,
              width: isMobile ? 600 : 1280,
            })
          : undefined
      }
      controls={false}
      isAutoplay={isAutoplay}
      play={({ onClick, isPlaying, isHover, isEnded }) => (
        <VideoPlayButton
          className={isButtonHover(isHover)}
          onClick={onClick}
          isPlaying={isPlaying}
          isEnded={isEnded}
        />
      )}
      mute={({ isMuted, onClick, isHover }) => (
        <VideoVolumeButton
          className={cn(
            "absolute bottom-xs right-xs text-others-white",
            isButtonHover(isHover),
          )}
          onClick={onClick}
          isMuted={isMuted}
        />
      )}
      {...props}
    />
  ) : null;
}

export { FeatureSectionVideo };
