import { storyblokEditable } from "@storyblok/react/rsc";
import type { PageContext } from "~lib/storyblok";
import { cn } from "~utils";
import { RichText } from "../rich-text";
import type { PitchItemBlok, PitchSectionBlok } from "./types";

type PitchSectionProps = {
  blok: PitchSectionBlok;
} & PageContext;

export function PitchSection({ blok, ...context }: PitchSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="pitch"
      className={cn(blok.theme, "section")}
      {...storyblokEditable(blok)}
    >
      <div className="grid gap-2xl md:grid-cols-2 md:gap-lg">
        <div
          className={cn(
            "-mt-md flex max-w-text flex-col place-items-start gap-sm self-start pt-md",
            "md:sticky md:top-3xl-4xl",
          )}
        >
          {blok.title && (
            <h1 className="t-strong-4xl text-content-heading">{blok.title}</h1>
          )}

          <RichText
            data={blok.text}
            className="t-strong-lg text-content-text"
            {...context}
          />
        </div>

        <ul className="flex flex-col gap-lg md:mt-3xl">
          {blok.items.map(item => (
            <PitchItem key={item._uid} blok={item} />
          ))}
        </ul>
      </div>
    </section>
  );
}

type PitchItemProps = {
  blok: PitchItemBlok;
};

export function PitchItem({ blok }: PitchItemProps) {
  return (
    <li
      {...storyblokEditable(blok)}
      className="flex items-center gap-sm border-border/200 border-b p-sm py-sm"
    >
      <div className="t-default-md grid max-w-title gap-3xs text-content-heading">
        {blok.title && <span className="t-strong-4xl">{blok.title}</span>}
        <span className="t-default-md">{blok.text}</span>
      </div>
    </li>
  );
}
